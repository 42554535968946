import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation, Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationLocationsService} from '../../services/reservationLocationsService'
import {reservationsService} from '../../services/reservationsService'
import {goToNewReservation} from '../../utils/navigation'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {createStorageProvider} from '../../utils/storageContext'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {getReservationMock} from '../../editor/editorMocks/getReservationMock'
import {ApprovalTextEditorState} from '../../utils/constants'

interface ReservationConfirmationData {
  getReservationLocationsStatus: RequestStatus
  getReservationStatus: RequestStatus
  cancelReservationStatus: RequestStatus
  cancelReservationError?: any

  regionalSettings?: string
  reservationId: string | undefined
  reservationLocations: ReservationLocation[]
  reservation: Reservation | undefined
  fitToContentHeight: boolean
  approvalTextEditorState: ApprovalTextEditorState.unknown
  isManualApproval: boolean
}

interface ReservationConfirmationActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  getReservation: (reservationId: string) => Promise<Reservation | undefined>
  cancelReservation: (param: {
    reservationId: string
    revision: string
    phone?: string
  }) => Promise<Reservation | undefined>
  resetCancelReservationStatus: () => void
  goToNewReservation: (reservation?: Reservation, timeZone?: string | null) => void
}

export interface ReservationConfirmationStorage
  extends ReservationConfirmationData,
    ReservationConfirmationActions {}

export const defaultReservationConfirmationStorage: ReservationConfirmationStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  getReservationStatus: RequestStatus.DEFAULT,
  cancelReservationStatus: RequestStatus.DEFAULT,
  cancelReservationError: undefined,
  reservationId: undefined,
  reservation: undefined,
  reservationLocations: [],
  getReservationLocations: noop,
  getReservation: noop,
  cancelReservation: noop,
  resetCancelReservationStatus: noop,
  goToNewReservation: noop,
  fitToContentHeight: true,
  approvalTextEditorState: ApprovalTextEditorState.unknown,
  isManualApproval: false,
}

const {withStorageProvider, useStorage} = createStorageProvider(
  defaultReservationConfirmationStorage,
)

const initReservationConfirmationStorage = (
  flowAPI: ControllerFlowAPI,
): ReservationConfirmationStorage => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query ?? {}
  const isWithQuery = !!query.reservationId

  return {
    ...defaultReservationConfirmationStorage,
    regionalSettings: getRegionalSettings(flowAPI),
    reservationId: isWithQuery ? query.reservationId : undefined,
    getReservationLocations: wrapRequest(
      flowAPI,
      reservationLocationsService.getReservationLocations,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getReservation: wrapRequest(
      flowAPI,
      reservationsService.getReservation,
      'reservation',
      'getReservationStatus',
    ),
    cancelReservation: wrapRequest(
      flowAPI,
      reservationsService.cancelReservation,
      'reservation',
      'cancelReservationStatus',
      'cancelReservationError',
    ),
    resetCancelReservationStatus: () => {
      flowAPI.controllerConfig.setProps({
        cancelReservationStatus: RequestStatus.DEFAULT,
        cancelReservationError: undefined,
      })
    },
    goToNewReservation: (reservation) =>
      goToNewReservation({
        flowAPI,
        reservationData: reservation?.details,
      }),
  }
}

const mockReservationConfirmationStorage = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationConfirmationStorage> => {
  const reservationLocations = await getReservationLocationsMock(flowAPI)
  const reservation = getReservationMock(flowAPI, Status.RESERVED, reservationLocations[0].id!)
  const isManualApproval =
    reservationLocations[0].configuration?.onlineReservations?.manualApproval?.enabled || false

  return {
    ...defaultReservationConfirmationStorage,
    reservationLocations,
    regionalSettings: getRegionalSettings(flowAPI),
    getReservationLocationsStatus: RequestStatus.RESOLVED,
    reservation,
    reservationId: reservation.id!,
    isManualApproval,
    cancelReservation: async () => {
      return {}
    },
  }
}

export {
  initReservationConfirmationStorage,
  mockReservationConfirmationStorage,
  withStorageProvider as withReservationConfirmationStorageProvider,
  useStorage as useReservationConfirmationStorage,
}
