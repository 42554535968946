import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Text} from 'wix-ui-tpa/cssVars'
import {TextTypography} from 'wix-ui-tpa'

import {Modal} from '../../../../components-shared/Modal'

import {classes, st} from './styles.st.css'
import {useHooks} from './useHooks'

export const ErrorModal: React.FC = () => {
  const {t} = useTranslation()
  const {isModalOpen, onClose, onCtaButtonClick} = useHooks()

  return (
    <Modal className={st(classes.root)} isOpen={isModalOpen} onClose={onClose}>
      <Text tagName="H2" typography={TextTypography.smallTitle} className={classes.title}>
        {t('uou-reservations.reservation-confirmation.error-modal-title')}
      </Text>
      <Text className={classes.text}>
        {t('uou-reservations.reservation-confirmation.error-modal-text')}
      </Text>
      <Button onClick={onCtaButtonClick} className={classes.ctaButton}>
        {t('uou-reservations.reservation-confirmation.error-modal-cta')}
      </Button>
    </Modal>
  )
}
